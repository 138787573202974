
/* Custom - DFriedel */



// Dump highlighting due to something setting a border on menu
*:focus {
    outline: none;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.width-100 {
    width: 100%;
}

.force-gray-text {
    color: #646464 !important;
}

a.text-supersize {
    color: #b1b1b1;
    font-size: 5em;
    font-weight: 900;
    border-bottom: none;
}

.head2spacing {
    vertical-align: middle;
}

.outline-box {
    border: 1px solid gray;
}

.object-message {
    font-size: .8em;
    margin-top: -20px;
    margin-bottom: 30px;
}

.autosize {
    width: 80%;
    height: auto;
  }
  
.not-found {
    text-align: center;
    margin: 5em;
  }
  
.icon-word {
	position: absolute;
	z-index: 5;
	top: 115px;
	right: 25px;
}

.icon-word-link {
	border: none !important;
}

article li, article h1, article p {
	text-align: left;
}

.super-center {
	text-align: center;
}

.my-name {
    font-size: 1.5em !important;
    font-weight: 900 !important;
}

.sr-only {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}